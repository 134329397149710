var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "chat"
  }, [_c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "md": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "setting-content"
  }, [_c('div', {
    staticClass: "setting-title"
  }, [_vm._v("智能助手页面配置")]), _c('Tabs', {
    model: {
      value: _vm.tabName,
      callback: function ($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c('TabPane', {
    attrs: {
      "label": "基础配置",
      "name": "basic"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "输入框",
      "name": "quick"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "公告",
      "name": "notice"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "反馈",
      "name": "feedback"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "未知答复",
      "name": "system"
    }
  })], 1), _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('Form', {
    ref: "emailForm",
    attrs: {
      "model": _vm.form,
      "label-width": 120,
      "label-position": "left",
      "rules": _vm.formValidate
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'basic',
      expression: "tabName == 'basic'"
    }]
  }, [_c('FormItem', {
    attrs: {
      "label": "对话框标题名称",
      "prop": "title"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "请输入对话框标题名称"
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c('FormItem', {
    staticClass: "form-noheight",
    attrs: {
      "label": "机器人头像",
      "prop": "avatar"
    }
  }, [_c('uploadPicInput', {
    model: {
      value: _vm.form.avatar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "avatar", $$v);
      },
      expression: "form.avatar"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "进入系统提示",
      "prop": "sysMessage"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "请输入进入系统提示"
    },
    model: {
      value: _vm.form.sysMessage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sysMessage", $$v);
      },
      expression: "form.sysMessage"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "初始消息",
      "prop": "welcomeMessage"
    }
  }, [_c('Input', {
    attrs: {
      "type": "textarea",
      "rows": 3,
      "placeholder": "请输入点踩后显示的文本"
    },
    model: {
      value: _vm.form.welcomeMessage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "welcomeMessage", $$v);
      },
      expression: "form.welcomeMessage"
    }
  })], 1), _c('FormItem', {
    staticClass: "form-noheight",
    attrs: {
      "label": "初始富文本消息",
      "prop": "initMessage"
    }
  }, [_c('editor', {
    attrs: {
      "id": "initMessage",
      "height": "200"
    },
    model: {
      value: _vm.form.initMessage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "initMessage", $$v);
      },
      expression: "form.initMessage"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'quick',
      expression: "tabName == 'quick'"
    }]
  }, [_c('FormItem', {
    attrs: {
      "label": "输入框占位文本",
      "prop": "placeholder"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "请输入输入框占位文本"
    },
    model: {
      value: _vm.form.placeholder,
      callback: function ($$v) {
        _vm.$set(_vm.form, "placeholder", $$v);
      },
      expression: "form.placeholder"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "快捷短语",
      "prop": "quickReplies"
    }
  }, [_c('vuedraggable', {
    attrs: {
      "list": _vm.tagList,
      "animation": 200,
      "ghost-class": "thumb-ghost"
    }
  }, _vm._l(_vm.tagList, function (item, index) {
    return _c('Tag', {
      key: index,
      class: {
        'ivu-close-color': item.isHighlight
      },
      attrs: {
        "size": "large",
        "closable": "",
        "color": item.isHighlight ? 'blue' : 'default'
      },
      on: {
        "on-close": function ($event) {
          return _vm.handleDelete(item);
        }
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.editQuickReply(item);
        }
      }
    }, [item.icon ? _c('span', [_vm._v("[" + _vm._s(item.icon) + "] ")]) : _vm._e(), _vm._v(_vm._s(item.name))]);
  }), 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('Button', {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      "icon": "md-add",
      "type": "dashed"
    },
    on: {
      "click": _vm.addQuickReply
    }
  }, [_vm._v("添加快捷短语")]), _c('Tooltip', {
    staticStyle: {
      "display": "inline-block !important",
      "padding-top": "5px"
    },
    attrs: {
      "content": "点击短语可编辑，可拖拽交换顺序",
      "placement": "right",
      "transfer": "",
      "max-width": "280"
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin-left": "10px",
      "cursor": "pointer"
    },
    attrs: {
      "type": "md-help-circle",
      "size": "20",
      "color": "#c5c5c5"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'notice',
      expression: "tabName == 'notice'"
    }]
  }, [_c('FormItem', {
    attrs: {
      "label": "公告开关",
      "prop": "noticeType"
    }
  }, [_c('i-switch', {
    attrs: {
      "true-value": 'richtext',
      "false-value": ''
    },
    model: {
      value: _vm.form.noticeType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "noticeType", $$v);
      },
      expression: "form.noticeType"
    }
  }, [_c('span', {
    attrs: {
      "slot": "open"
    },
    slot: "open"
  }, [_vm._v("开")]), _c('span', {
    attrs: {
      "slot": "close"
    },
    slot: "close"
  }, [_vm._v("关")])]), _c('Tooltip', {
    staticStyle: {
      "display": "inline-block !important"
    },
    attrs: {
      "content": "开启后将在PC端的右侧显示公告内容",
      "placement": "right",
      "transfer": "",
      "max-width": "280"
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin-left": "10px",
      "cursor": "pointer"
    },
    attrs: {
      "type": "md-help-circle",
      "size": "20",
      "color": "#c5c5c5"
    }
  })], 1)], 1), _c('FormItem', {
    attrs: {
      "label": "公告标题",
      "prop": "noticeTitle"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "请输入公告标题"
    },
    model: {
      value: _vm.form.noticeTitle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "noticeTitle", $$v);
      },
      expression: "form.noticeTitle"
    }
  })], 1), _c('FormItem', {
    staticClass: "form-noheight",
    attrs: {
      "label": "公告内容",
      "prop": "noticeContent"
    }
  }, [_c('editor', {
    attrs: {
      "id": "noticeContent",
      "height": "250"
    },
    model: {
      value: _vm.form.noticeContent,
      callback: function ($$v) {
        _vm.$set(_vm.form, "noticeContent", $$v);
      },
      expression: "form.noticeContent"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'feedback',
      expression: "tabName == 'feedback'"
    }]
  }, [_c('FormItem', {
    attrs: {
      "label": "点赞后反馈文本",
      "prop": "textOfGood"
    }
  }, [_c('Input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "placeholder": "请输入点赞后显示的文本"
    },
    model: {
      value: _vm.form.textOfGood,
      callback: function ($$v) {
        _vm.$set(_vm.form, "textOfGood", $$v);
      },
      expression: "form.textOfGood"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "点踩后反馈文本",
      "prop": "textOfBad"
    }
  }, [_c('Input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "placeholder": "请输入点踩后显示的文本"
    },
    model: {
      value: _vm.form.textOfBad,
      callback: function ($$v) {
        _vm.$set(_vm.form, "textOfBad", $$v);
      },
      expression: "form.textOfBad"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'system',
      expression: "tabName == 'system'"
    }]
  }, [_c('FormItem', {
    staticClass: "form-noheight",
    attrs: {
      "label": "未找到任何匹配回答的答复",
      "prop": "noDataReply"
    }
  }, [_c('editor', {
    attrs: {
      "id": "noDataReply",
      "height": "250"
    },
    model: {
      value: _vm.form.noDataReply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "noDataReply", $$v);
      },
      expression: "form.noDataReply"
    }
  })], 1)], 1), _c('FormItem', [_c('Button', {
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "type": "primary",
      "loading": _vm.saveLoading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存更改")])], 1)], 1), _vm.loading ? _c('Spin', {
    attrs: {
      "fix": ""
    }
  }) : _vm._e()], 1)], 1)]), _c('Col', {
    staticClass: "iphone-content",
    attrs: {
      "md": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "demo-model",
    style: {
      backgroundImage: 'url(' + require('@/assets/icon/iPhoneX.png') + ')'
    }
  }, [_c('div', {
    staticClass: "model-content"
  }, [_c('iframe', {
    staticClass: "iframe",
    attrs: {
      "scrolling": "auto",
      "frameborder": "0",
      "src": _vm.demoUrl,
      "id": "demo-modal"
    }
  })])])])], 1), _c('div', {
    staticClass: "preview-pc",
    on: {
      "click": function ($event) {
        return _vm.showDemo(true);
      }
    }
  }, [_c('Icon', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "type": "md-desktop"
    }
  }), _vm._v("预览PC版 ")], 1)], 1), _c('Modal', {
    attrs: {
      "title": _vm.modalTitle,
      "mask-closable": false,
      "width": 500
    },
    model: {
      value: _vm.modalVisible,
      callback: function ($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c('Form', {
    ref: "replyForm",
    attrs: {
      "model": _vm.replyForm,
      "label-position": "top",
      "rules": _vm.replyFormValidate
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "快捷短语",
      "prop": "name"
    }
  }, [_c('Input', {
    model: {
      value: _vm.replyForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.replyForm, "name", $$v);
      },
      expression: "replyForm.name"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "图标",
      "prop": "icon"
    }
  }, [_c('Select', {
    attrs: {
      "filterable": "",
      "clearable": ""
    },
    model: {
      value: _vm.replyForm.icon,
      callback: function ($$v) {
        _vm.$set(_vm.replyForm, "icon", $$v);
      },
      expression: "replyForm.icon"
    }
  }, [_c('Option', {
    attrs: {
      "value": "alarm"
    }
  }, [_vm._v("闹钟 alarm")]), _c('Option', {
    attrs: {
      "value": "apps"
    }
  }, [_vm._v("应用 apps")]), _c('Option', {
    attrs: {
      "value": "bullhorn"
    }
  }, [_vm._v("喇叭 bullhorn")]), _c('Option', {
    attrs: {
      "value": "camera"
    }
  }, [_vm._v("相机 camera")]), _c('Option', {
    attrs: {
      "value": "cart"
    }
  }, [_vm._v("购物车 cart")]), _c('Option', {
    attrs: {
      "value": "close"
    }
  }, [_vm._v("关闭 close")]), _c('Option', {
    attrs: {
      "value": "check"
    }
  }, [_vm._v("确认 check")]), _c('Option', {
    attrs: {
      "value": "edit"
    }
  }, [_vm._v("编辑 edit")]), _c('Option', {
    attrs: {
      "value": "ellipsis-h"
    }
  }, [_vm._v("更多 ellipsis-h")]), _c('Option', {
    attrs: {
      "value": "file"
    }
  }, [_vm._v("文件 file")]), _c('Option', {
    attrs: {
      "value": "folder"
    }
  }, [_vm._v("文件夹 folder")]), _c('Option', {
    attrs: {
      "value": "home"
    }
  }, [_vm._v("首页 home")]), _c('Option', {
    attrs: {
      "value": "image"
    }
  }, [_vm._v("图片 image")]), _c('Option', {
    attrs: {
      "value": "info-circle"
    }
  }, [_vm._v("提示 info-circle")]), _c('Option', {
    attrs: {
      "value": "keyboard"
    }
  }, [_vm._v("键盘 keyboard")]), _c('Option', {
    attrs: {
      "value": "message"
    }
  }, [_vm._v("消息 message")]), _c('Option', {
    attrs: {
      "value": "mic"
    }
  }, [_vm._v("麦克风 mic")]), _c('Option', {
    attrs: {
      "value": "paper-plane"
    }
  }, [_vm._v("发送 paper-plane")]), _c('Option', {
    attrs: {
      "value": "plus"
    }
  }, [_vm._v("加号 plus")]), _c('Option', {
    attrs: {
      "value": "refresh"
    }
  }, [_vm._v("刷新 refresh")]), _c('Option', {
    attrs: {
      "value": "search"
    }
  }, [_vm._v("搜索 search")]), _c('Option', {
    attrs: {
      "value": "share"
    }
  }, [_vm._v("分享 share")]), _c('Option', {
    attrs: {
      "value": "smile"
    }
  }, [_vm._v("笑脸 smile")]), _c('Option', {
    attrs: {
      "value": "tel"
    }
  }, [_vm._v("电话 tel")]), _c('Option', {
    attrs: {
      "value": "thumbs-down"
    }
  }, [_vm._v("点赞 thumbs-down")]), _c('Option', {
    attrs: {
      "value": "thumbs-up"
    }
  }, [_vm._v("点踩 thumbs-up")])], 1)], 1), _c('FormItem', {
    attrs: {
      "label": "高亮显示",
      "prop": "isHighlight"
    }
  }, [_c('i-switch', {
    model: {
      value: _vm.replyForm.isHighlight,
      callback: function ($$v) {
        _vm.$set(_vm.replyForm, "isHighlight", $$v);
      },
      expression: "replyForm.isHighlight"
    }
  }, [_c('span', {
    attrs: {
      "slot": "open"
    },
    slot: "open"
  }, [_vm._v("开")]), _c('span', {
    attrs: {
      "slot": "close"
    },
    slot: "close"
  }, [_vm._v("关")])])], 1), _c('FormItem', {
    attrs: {
      "label": "右上角红点",
      "prop": "isNew"
    }
  }, [_c('i-switch', {
    model: {
      value: _vm.replyForm.isNew,
      callback: function ($$v) {
        _vm.$set(_vm.replyForm, "isNew", $$v);
      },
      expression: "replyForm.isNew"
    }
  }, [_c('span', {
    attrs: {
      "slot": "open"
    },
    slot: "open"
  }, [_vm._v("开")]), _c('span', {
    attrs: {
      "slot": "close"
    },
    slot: "close"
  }, [_vm._v("关")])])], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        _vm.modalVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };