import { getAutoChatSet, editAutoChatSet, getOtherSet } from "@/api/index";
import editor from "@/views/my-components/xboot/editor";
import uploadPicInput from "@/views/my-components/xboot/upload-pic-input";
import vuedraggable from "vuedraggable";
export default {
  name: "chat-setting",
  components: {
    editor,
    uploadPicInput,
    vuedraggable
  },
  data() {
    return {
      tabName: "basic",
      loading: false,
      // 表单加载状态
      saveLoading: false,
      form: {
        noticeType: ""
      },
      formValidate: {},
      modalType: 0,
      // 添加或编辑标识
      modalVisible: false,
      // 添加或编辑显示
      modalTitle: "",
      // 添加或编辑标题
      replyForm: {
        name: "",
        icon: "",
        isHighlight: false,
        isNew: false
      },
      replyFormValidate: {
        name: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }]
      },
      tagList: [],
      domain: "",
      demoUrl: ""
    };
  },
  methods: {
    init() {
      this.getData();
      this.initUrl();
    },
    getData() {
      this.loading = true;
      getAutoChatSet().then(res => {
        this.loading = false;
        if (res.success) {
          if (res.result) {
            // 转换null为""
            let v = res.result;
            for (let attr in v) {
              if (v[attr] == null || v[attr] == "null") {
                v[attr] = "";
              }
            }
            let str = JSON.stringify(v);
            let data = JSON.parse(str);
            this.form = data;
            if (res.result.quickReplies && res.result.quickReplies.length >= 0 && res.result.quickReplies != "null") {
              this.tagList = JSON.parse(res.result.quickReplies);
            }
          }
        }
      });
    },
    initUrl() {
      getOtherSet().then(res => {
        if (res.result) {
          this.domain = res.result.domain;
          this.showDemo();
        }
      });
    },
    showDemo(previewPC) {
      if (!this.domain) {
        this.$Modal.confirm({
          title: "您还未配置访问域名",
          content: "您还未配置应用部署访问域名，是否现在立即去配置?（配置后请刷新该页面）",
          onOk: () => {
            this.$router.push({
              name: "setting",
              query: {
                name: "other"
              }
            });
          }
        });
        return;
      }
      this.demoUrl = `${this.domain}/chat/chat.html?accessToken=${this.getStore("accessToken")}&time=${new Date()}`;
      if (previewPC) {
        window.open(this.demoUrl);
      }
    },
    addQuickReply() {
      this.modalType = 0;
      this.modalTitle = "添加快捷短语";
      this.$refs.replyForm.resetFields();
      this.modalVisible = true;
    },
    editQuickReply(v) {
      this.modalType = 1;
      this.modalTitle = "编辑快捷短语";
      // 转换null为""
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.replyForm = data;
      this.modalVisible = true;
    },
    handleDelete(v) {
      this.tagList = this.tagList.filter(e => {
        return e.name != v.name;
      });
    },
    handleSubmit() {
      this.$refs.replyForm.validate(valid => {
        if (valid) {
          if (this.modalType == 0) {
            // 添加
            this.tagList.push({
              id: new Date().getTime(),
              name: this.replyForm.name,
              icon: this.replyForm.icon,
              isHighlight: this.replyForm.isHighlight,
              isNew: this.replyForm.isNew
            });
            this.modalVisible = false;
          } else {
            // 编辑
            for (let i = 0; i < this.tagList.length; i++) {
              if (this.tagList[i].id == this.replyForm.id) {
                this.tagList[i] = this.replyForm;
              }
            }
            this.modalVisible = false;
          }
        }
      });
    },
    submit() {
      this.submitLoading = true;
      this.form.quickReplies = JSON.stringify(this.tagList);
      editAutoChatSet(this.form).then(res => {
        this.submitLoading = false;
        if (res.success) {
          this.$Message.success("操作成功");
          this.showDemo();
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};